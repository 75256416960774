/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Slider from 'react-slick';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { getWorkshopURL, remove_duplicates, removeDuplicates } from '../../../utils';
import { selectMasterclassesCourses } from '../../../redux/slice/masterclassesAndCoursesSlice';
import MobileNav from './MobileNav';

const CarouselSettings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: 3,
    // rows: 2,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                rows: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                slidesPerRow: 1,
                rows: 1,
            },
        },
        {
            breakpoint: 500,
            settings: {
                arrows: false,
                slidesToShow: 1.3,
                slidesToScroll: 1,
                slidesPerRow: 2,
                rows: 1,
            },
        },
    ],
};

const ResponsiveMenu = ({ show, onClose, showSearch, onSearch, categories, trips }) => {
    useEffect(() => {
        var elements = document.querySelectorAll('.popup-mobile-menu .has-droupdown > a');
        var elementsTwo = document.querySelectorAll('.popup-mobile-menu .with-megamenu > a');
        for (var i in elements) {
            // eslint-disable-next-line no-prototype-builtins
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu')?.classList.toggle('active');
                    this.classList.toggle('open');
                };
            }
        }

        for (var j in elementsTwo) {
            // eslint-disable-next-line no-prototype-builtins
            if (elementsTwo.hasOwnProperty(i)) {
                elementsTwo[j].onclick = function () {
                    this.parentElement.querySelector('.rn-megamenu')?.classList.toggle('active');
                    this.classList.toggle('open');
                };
            }
        }
    }, []);

    // const { success } = useSelector((state) => state.categories);

    // const { masterclasses } = useSelector((state) => state.masterclasses);
    // const { courses } = useSelector((state) => state.courses);

    const { courses } = useSelector(selectMasterclassesCourses);


    const [searchData, setSearchData] = useState({
        courses: [],
        masterclasses: [],
    });
    const [searchResult, setSearchResult] = useState({
        courses: [],
        masterclasses: [],
        topSellings: [],
    });

    const [showingRecommended, setShowingRecommended] = useState(false);

    useEffect(() => {
        if (courses.ALL.length) {
            setSearchData({
                masterclasses: courses.MASTERCLASSES,
                courses: courses.COURSES,
            });
        }
    }, [courses.ALL.length, courses.COURSES, courses.MASTERCLASSES]);

    const [searchInitiated, setSearchInitiated] = useState(false);

    const searchHandle = (e) => {
        const _query = e.target.value;

        if (_query !== '') {
            const _masterclassesResult = searchData.masterclasses.filter((item) =>
                String(`${item?.searchKeys},${item?.name}`).toLowerCase().includes(String(e.target.value).toLowerCase())
            );
            const _coursesResult = searchData.courses.filter((item) =>
                String(`${item?.searchKeys},${item?.name}`).toLowerCase().includes(String(e.target.value).toLowerCase())
            );

            if (_masterclassesResult.length || _coursesResult.length) {
                setSearchResult({
                    masterclasses: removeDuplicates(_masterclassesResult.slice(0, 2), 'id'),
                    courses: removeDuplicates(_coursesResult.slice(0, 2), 'id'),
                    topSellings: [],
                });
                setShowingRecommended(false);
            } else {
                if (topSellings && topSellings.length) {
                    setSearchResult({
                        masterclasses: [],
                        courses: [],
                        topSellings: Array.from(topSellings).slice(0, 3),
                    });
                } else {
                    setSearchResult({
                        courses: [],
                        masterclasses: [],
                        topSellings: [],
                    });
                    setShowingRecommended(false);
                }
                setShowingRecommended(true);
            }
            setSearchInitiated(true);
        } else {
            setSearchResult({
                courses: [],
                masterclasses: [],
                topSellings: [],
            });
            setShowingRecommended(false);
            setSearchInitiated(false);
        }
    };

    const inputRef = useRef(null);

    useEffect(() => {
        if (showSearch) {
            setTimeout(() => {
                const searchPopup = document.querySelector('#popup-mobile-menu');
                if (searchPopup) {
                    searchPopup.classList.contains('active');
                    const inputField = document.getElementById('mobileSearchInput');
                    if (inputField) {
                        inputField.focus();
                    }
                }
            }, 200);
        }
    }, [showSearch]);

    const clearSearchHandle = () => {
        setSearchResult({
            courses: [],
            masterclasses: [],
            topSellings: [],
        });
        onSearch();
    };

    // const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(fetchTopSellingCourses());
    // }, [dispatch]);

    // const { masterclasses: shortTermCourses } = useSelector((state) => state.masterclasses);
    const { courses: topSellings } = useSelector((state) => state.topSellings);

    const router = useRouter();

    let _params = '';

    Object.entries(router.query)
        .filter((item) => item[0] !== 'paymentId')
        .filter((item) => item[0] !== 'id')
        .filter((item) => item[0] !== 'name')
        .forEach((item) => {
            _params += `&${item[0]}=${item[1]}`;
        });




    return (
        <>
            <div className={`popup-mobile-menu ${show ? 'active' : ''}`} id='popup-mobile-menu'>
                <div className='inner'>
                    <div className='header-top'>
                        <div className='logo'>
                            <Link href='/'>
                                <a>
                                    <img src='/kaarwan/kaarwan-logo.svg' alt='Kaarwan-Logo' loading='lazy' />
                                </a>
                            </Link>
                        </div>
                        <div className='close-menu'>
                            <button
                                type='button'
                                name='close'
                                aria-label='close'
                                className='close-button'
                                onClick={onClose}
                            >
                                <i className='ri-close-line'></i>
                            </button>
                        </div>
                    </div>
                    <MobileNav categories={categories} trips={trips} show={show} onClose={onClose} />
                </div>
            </div>

            <div className={`edu-search-popup ${showSearch ? 'open' : ''}`} id='edu_popup_search'>
                <div className='mobile-search-input-container'>
                    <div className='close-button d-flex align-items-center' style={{ height: '41px' }}>
                        {/* <button type='button' name='close' aria-label='close' className='close-trigger' onClick={onSearch}>
              <i className='ri-arrow-left-line'></i>
            </button> */}
                        <button type='button' className='border-0 bg-transparent' aria-label='close' onClick={onSearch}>
                            <ChevronLeft />
                        </button>
                    </div>
                    <div className='inner d-flex align-items-center w-100'>
                        <div className='search-form d-flex align-items-center w-100'>
                            <button
                                type='button'
                                onClick={() => {
                                    inputRef.current.focus();
                                }}
                            >
                                <SearchIcon />
                            </button>
                            <input
                                id='mobileSearchInput'
                                className='eduvibe-search-popup-field w-100'
                                placeholder='Search for courses'
                                onKeyUp={searchHandle}
                                autoComplete='off'
                                style={{ outline: 'none' }}
                                ref={inputRef}
                            />
                        </div>
                        <button
                            type='button'
                            className='border-0 bg-transparent'
                            onClick={() => {
                                setSearchInitiated(false);
                                inputRef.current.value = '';
                            }}
                        >
                            <CrossIcon />
                        </button>
                    </div>
                </div>
                <div className='search-result-mobile-v2'>
                    {!searchInitiated ? (
                        <div className='default-data'>
                            <div className='search-header d-flex align-items-center m-auto'>
                                <span className='short-bar'>{ }</span>{' '}
                                <span className='title'>High Demand 1-Day Masterclasses</span>
                            </div>

                            <div className='hr-scrollable-result'>
                                {courses.MASTERCLASSES && courses.MASTERCLASSES.length ? (
                                    <Slider {...CarouselSettings}>
                                        {remove_duplicates([...courses.MASTERCLASSES]).map((item) => (
                                            <Link
                                                href={getWorkshopURL(1, item?.name, item?.id, item?.url, _params)}
                                                key={item?.id}
                                            >
                                                <a
                                                    className='course-card-sm d-flex align-items-start'
                                                    onClick={onSearch}
                                                >
                                                    <div className='image-container'>
                                                        <Image
                                                            alt={item?.name}
                                                            width={40}
                                                            height={40}
                                                            objectFit='cover'
                                                            src={`${process.env.NEXT_PUBLIC_MEDIA_URL}/${item?.image}`}
                                                            className='course-thumb'
                                                            loading='lazy'
                                                            quality={''}
                                                        />
                                                    </div>
                                                    <h5 className='m-0 two-line-title'>{item?.name}</h5>
                                                </a>
                                            </Link>
                                        ))}
                                    </Slider>
                                ) : null}
                            </div>

                            <div
                                className='search-header d-flex align-items-center m-auto'
                                style={{ paddingTop: '18px !important' }}
                            >
                                <span className='short-bar'>{ }</span>{' '}
                                <span className='title'>Top Selling Certification Courses</span>
                            </div>
                            <div className='hr-scrollable-result'>
                                {courses.TOP_SELLINGS && courses.TOP_SELLINGS.length ? (
                                    <Slider {...CarouselSettings}>
                                        {removeDuplicates(courses.TOP_SELLINGS, 'id').map((item) => (
                                            <Link
                                                href={getWorkshopURL(2, item?.name, item?.id, item?.url, _params)}
                                                key={item?.id}
                                            >
                                                <a
                                                    className='course-card-sm d-flex align-items-start'
                                                    onClick={onSearch}
                                                >
                                                    <div className='image-container'>
                                                        <Image
                                                            alt={item?.name}
                                                            width={40}
                                                            height={40}
                                                            objectFit='cover'
                                                            src={`${process.env.NEXT_PUBLIC_MEDIA_URL}/${item?.image}`}
                                                            className='course-thumb'
                                                            loading='lazy'
                                                            quality={''}
                                                        />
                                                    </div>
                                                    <h5 className='m-0 two-line-title'>{item?.name}</h5>
                                                </a>
                                            </Link>
                                        ))}
                                    </Slider>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='search-result-mobile' style={{ marginTop: '55px' }}>
                                {showingRecommended ? (
                                    <div className='header-content' style={{ paddingTop: '0' }}>
                                        <p className='recommended-label m-0'>
                                            No courses found, Try different keywords.
                                        </p>
                                        <h6 className='header m-0'>Courses that we think you'll find valuable</h6>
                                    </div>
                                ) : null}

                                {showingRecommended ? (
                                    <>
                                        {removeDuplicates(courses.TOP_SELLINGS, 'id').map((item) => (
                                            <Link
                                                key={item.id}
                                                href={getWorkshopURL(
                                                    item?.type,
                                                    item?.name,
                                                    item?.id,
                                                    item?.url,
                                                    _params
                                                )}
                                            >
                                                <a className='d-flex align-items-center title-card' onClick={onSearch}>
                                                    <Image
                                                        alt={item?.name}
                                                        width={40}
                                                        height={40}
                                                        objectFit='cover'
                                                        src={`${process.env.NEXT_PUBLIC_MEDIA_URL}/${item?.image}`}
                                                        className='course-thumb'
                                                        loading='lazy'
                                                        quality={''}
                                                    />
                                                    <h6 className='title my-0 mx-3'>{item?.name}</h6>
                                                </a>
                                            </Link>
                                        ))}
                                    </>
                                ) : null}

                                {searchResult.courses.length > 0 ? (
                                    <div className='search-header d-flex align-items-center m-auto'>
                                        <span className='short-bar'>{ }</span>{' '}
                                        <span className='title'>Certification Courses</span>
                                    </div>
                                ) : null}

                                {searchResult.courses.map((item) => (
                                    <Link
                                        key={item.id}
                                        href={getWorkshopURL(item?.type, item?.name, item?.id, item?.url, _params)}
                                    >
                                        <a className='d-flex align-items-center title-card' onClick={onSearch}>
                                            <Image
                                                alt={item?.name}
                                                width={40}
                                                height={40}
                                                objectFit='cover'
                                                src={`${process.env.NEXT_PUBLIC_MEDIA_URL}/${item?.image}`}
                                                className='course-thumb'
                                                loading='lazy'
                                                quality={''}
                                            />
                                            <h6 className='title my-0 mx-3'>{item?.name}</h6>
                                        </a>
                                    </Link>
                                ))}

                                {searchResult.masterclasses.length > 0 ? (
                                    <div
                                        className='search-header d-flex align-items-center m-auto'
                                        style={{ paddingTop: '18px' }}
                                    >
                                        <span className='short-bar'>{ }</span>{' '}
                                        <span className='title'>1-Day Masterclasses</span>
                                    </div>
                                ) : null}
                                {searchResult.masterclasses.map((item) => (
                                    <Link
                                        key={item.id}
                                        href={getWorkshopURL(item?.type, item?.name, item?.id, item?.url, _params)}
                                    >
                                        <a className='d-flex align-items-center title-card' onClick={clearSearchHandle}>
                                            <Image
                                                alt={item?.name}
                                                width={40}
                                                height={40}
                                                objectFit='cover'
                                                src={`${process.env.NEXT_PUBLIC_MEDIA_URL}/${item?.image}`}
                                                className='course-thumb'
                                                loading='lazy'
                                                quality={''}
                                            />
                                            <h6 className='title my-0 mx-3'>{item?.name}</h6>
                                        </a>
                                    </Link>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default ResponsiveMenu;

const ChevronLeft = (props) => (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path d='M15 19L8 12L15 5' stroke='#2F2F2F' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
);

const CrossIcon = (props) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
        <path d='M16 8L8 16' stroke='#5E5E5E' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M8 8L16 16' stroke='#5E5E5E' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
);

const SearchIcon = (props) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} viewBox='0 0 24 24' fill='none' {...props}>
        <path
            d='M11.3338 16.6677C14.2796 16.6677 16.6677 14.2796 16.6677 11.3338C16.6677 8.38804 14.2796 6 11.3338 6C8.38804 6 6 8.38804 6 11.3338C6 14.2796 8.38804 16.6677 11.3338 16.6677Z'
            stroke='#5E5E5E'
            strokeWidth={0.833437}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M18.0017 17.9998L15.1348 15.1328'
            stroke='#5E5E5E'
            strokeWidth={0.833437}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
